import { produce } from 'immer';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import type { SuppliersModel } from '../../../../../../Shared/js/@types/SuppliersModel';
import { RootState } from '../../../../../../Shared/js/redux/rootReducer';
import AddressChangeTracker from '../../../../../../Shared/React/js/components/addressSearch/AddressChangeTracker';
import type { SearchCriteria } from '../../../../../js/@types/SearchCriteria';
import type { SearchFilters } from '../../../../../js/@types/SearchFilters';
import { actions as modalActions } from '../../../../../js/redux/modalSlice';
import AdvancedTab from './AdvancedTab';
import BundleTab from './BundleTab';
import ConnectionTab from './connection/ConnectionTab';
import DataTab from './DataTab';
import FaqTab from './FaqTab';
import SpeedTab from './SpeedTab';
import SpendTab from './SpendTab';
import SuppliersTab from './SuppliersTab';
import TabContent from './TabContent';
import TabMenuItem from './TabMenuItem';

function mapStateToProps(
    state: RootState,
    ownProps: {
        isOpen?: boolean;
        activeTab?: string;
        criteria: SearchCriteria;
    }
) {
    return produce(ownProps, draft => {
        const modalState = state.broadband?.modal;

        draft.isOpen = defaultTo(modalState.isOpen, ownProps.isOpen);
        draft.activeTab = defaultTo(modalState.activeTab, ownProps.activeTab);

        draft.criteria = isEmpty(modalState.criteria) ? ownProps.criteria : modalState.criteria;
    });
}

const mapDispatchToProps = {
    ...modalActions
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, SearchFilters {
    hideSupplierFilter: boolean;
    showMaxUpfrontSlider: boolean;
    suppliers: SuppliersModel;
    unavailableConnectionTypes: never[];
    onCriteriaChanged: (criteria: SearchCriteria) => void;
}

const FilterModal = (props: Props) => {
    const containerRef = useRef(null);

    // TODO: Consider removing
    const [criteria, setCriteria] = useState(props.criteria);

    const handleIsOpenChanged = useCallback(isOpen => {
        if (containerRef.current === null) {
            return;
        }
        const action = isOpen ? 'show' : 'hide';
        wo$(containerRef.current).modal(action);
    }, []);

    const handleOnCriteriaChanged = useCallback(criteria => {
        setCriteria(criteria);
    }, []);

    const handleOnTabSelected = useCallback(
        (e: React.MouseEvent) => {
            props.selectTab(e.currentTarget.getAttribute('data-tab-name'));
        },
        [props]
    );

    const applyFilters = useCallback(() => {
        props.onCriteriaChanged(criteria);
    }, [criteria, props]);

    const handleAddressChange = useCallback(() => props.addressChanged(), [props]);

    const handleSuppliersChanged = useCallback(
        (selectedShortUrls, customerType) => props.updateSelectedSuppliers({ keys: selectedShortUrls, customerType }),
        [props]
    );

    const handleUpdateSpend = useCallback((min, max) => props.updateSpend({ min, max }), [props]);

    const isTabActive = useCallback(name => name === props.activeTab, [props.activeTab]);

    const hideModal = useCallback(() => props.hideModal(), [props]);

    const handleOnMount = useCallback(() => {
        if (containerRef.current === null) {
            return;
        }

        const container = wo$(containerRef.current);
        container.on('hidden.bs.modal', hideModal);

        // Unmount
        return () => {
            container.off('hidden.bs.modal', hideModal);
        };
    }, [hideModal]);

    useEffect(() => handleOnMount(), [handleOnMount]);

    useEffect(() => handleIsOpenChanged(props.isOpen), [handleIsOpenChanged, props.isOpen]);

    useEffect(() => handleOnCriteriaChanged(props.criteria), [handleOnCriteriaChanged, props.criteria]);

    const totalConnectionTypes =
        props.connectionTypeTiers.tier1ConnectionTypes.length +
        props.connectionTypeTiers.tier2ConnectionTypes.length +
        props.connectionTypeTiers.tier3ConnectionTypes.length;

    const tabNames = useMemo(() => {
        const items = ['spend', 'speed', 'data'];
        
        if (!props.hideSupplierFilter) {
            items.push('providers');
        }

        items.push('bundle');

        if (totalConnectionTypes > 1) {
            items.push('connection');
        }

        items.push('advanced');

        if (props.faqContentFragment && props.faqContentFragment.length > 0) {
            items.push('faq');
        }

        return items;
    }, [props.faqContentFragment, props.hideSupplierFilter, totalConnectionTypes]);

    const tabMenuItems = tabNames.map(name => {
        const text = get(props.resources, name);
        return (
            <TabMenuItem
                key={name}
                name={name}
                isActive={name === props.activeTab}
                text={text}
                onSelected={handleOnTabSelected}
            />
        );
    });

    if (!props.isOpen) {
        return null;
    }

    return (
        <div
            ref={containerRef}
            id="modal-results-broadband"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="modal-results-broadband"
            aria-hidden="true"
        >
            <AddressChangeTracker onAddressChange={handleAddressChange} />
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header pad-t-5 pad-b-3">
                        <strong className="font-7" id="modal-results-broadband-filter">
                            {props.resources.filter}
                            <span className="hidden-xs"> {props.resources.results}</span>
                        </strong>
                        <div className="float-right position-relative-xs">
                            <button className="btn btn-info" data-dismiss="modal">
                                <span className="fa fa-times font-5"></span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="[ col-xs-24 ] [ mar-b-5 ] ">
                                <ul className="nav nav-pills">{tabMenuItems}</ul>
                            </div>
                        </div>
                        <div className="row pad-x-4">
                            <div className="[ col-xs-24 ]">
                                <div className="tab-content">
                                    <TabContent name="speed" isActive={isTabActive}>
                                        <SpeedTab
                                            currentSpeed={criteria.speed}
                                            speedValues={props.speedSlider.values}
                                            anyLabel={props.speedSlider.anySpeedLabel}
                                            fromLabel={props.speedSlider.fromLabel}
                                            speedDescription={props.resources.speedDescription}
                                            speedContentFragment={props.speedContentFragment}
                                            onSpeedChange={props.updateSpeed}
                                        />
                                    </TabContent>
                                    <TabContent name="data" isActive={isTabActive}>
                                        <DataTab
                                            currentData={criteria.data}
                                            values={props.dataSlider.values}
                                            includedDataLabel={props.resources.includedData}
                                            anyDataLabel={props.dataSlider.anyDataLabel}
                                            atLeastLabel={props.dataSlider.fromLabel}
                                            buttonsContentFragment={props.dataContentFragment}
                                            changeData={props.updateData}
                                        />
                                    </TabContent>
                                    <TabContent name="spend" isActive={isTabActive}>
                                        <SpendTab
                                            resources={props.resources}
                                            criteria={criteria}
                                            spendSlider={props.spendSlider}
                                            upfrontSlider={props.upfrontSlider}
                                            spendContentFragment={props.spendContentFragment}
                                            updateSpend={handleUpdateSpend}
                                            updateUpfront={props.updateUpfront}
                                            showMaxUpfrontSlider={props.showMaxUpfrontSlider}
                                        />
                                    </TabContent>
                                    <TabContent name="providers" isActive={isTabActive}>
                                        <SuppliersTab
                                            key="suppliers"
                                            resources={props.resources}
                                            selectedShortUrls={criteria.common.suppliers.values}
                                            customerType={criteria.common.customerType}
                                            hasAddress={!!criteria.common.address}
                                            onSelectionChanged={handleSuppliersChanged}
                                            suppliers={props.suppliers.items
                                                .slice()
                                                .sort((a, b) => a.name.localeCompare(b.name))}
                                        />
                                    </TabContent>
                                    <TabContent name="bundle" isActive={isTabActive}>
                                        <BundleTab
                                            resources={props.resources}
                                            criteria={criteria}
                                            lineRentalOptions={props.lineRentalOptions}
                                            tvOptions={props.tvOptions}
                                            phoneOptions={props.phoneOptions}
                                            phoneCallsOptions={props.phoneCallsOptions}
                                            showTvChannels={props.showTvChannels}
                                            showLineRental={props.showLineRental}
                                            showPhoneBundlePreferences={props.showPhoneBundlePreferences}
                                            individualTvChannels={props.individualTvChannels}
                                            kidsTvChannels={props.kidsTvChannels}
                                            sportsTvChannels={props.sportsTvChannels}
                                            showMobilePhoneBundle={props.showMobilePhoneBundle}
                                            mobilePhoneBundleOptions={props.mobilePhoneBundleOptions}
                                            updatePhoneBundle={props.updatePhoneBundle}
                                            updatePhoneCallsBundle={props.updatePhoneCallsBundle}
                                            updateTvBundle={props.updateTvBundle}
                                            updateTvChannels={props.updateTvChannels}
                                            updateLineRentalBundle={props.updateLineRentalBundle}
                                            updateMobileBundle={props.updateMobileBundle}
                                        />
                                    </TabContent>
                                    <TabContent name="connection" isActive={isTabActive}>
                                        <ConnectionTab
                                            resources={props.resources}
                                            criteria={criteria}
                                            connectionTypeTiers={props.connectionTypeTiers}
                                            connectionTypeCategories={props.connectionTypeCategories}
                                            allConnectionTypes={props.allConnectionTypes}
                                            showTvChannels={props.showTvChannels}
                                            connectionTypesContentFragment={props.connectionTypesContentFragment}
                                            unavailableConnectionTypes={props.unavailableConnectionTypes}
                                            updateConnectionTypes={props.updateConnectionTypes}
                                        />
                                    </TabContent>
                                    <TabContent name="advanced" isActive={isTabActive}>
                                        <AdvancedTab
                                            key="advanced"
                                            criteria={criteria}
                                            resources={props.resources}
                                            allContractTerms={props.allContractTerms}
                                            contractTerms={props.contractTerms}
                                            prepaidExpiryValues={props.prepaidExpiryValues}
                                            currentlyWithValues={props.currentlyWithValues}
                                            homeModemTypes={props.homeModemTypes}
                                            mobileModemTypes={props.mobileModemTypes}
                                            homeModemsLabel={props.homeModemsLabel}
                                            mobileModemsLabel={props.mobileModemsLabel}
                                            anyModemLabel={props.anyModemLabel}
                                            dealsOptions={props.dealsOptions}
                                            megaAdvancedOptions={props.megaAdvancedOptions}
                                            showMobileModems={props.showMobileModems}
                                            updateContractTerms={props.updateContractTerms}
                                            updatePrepaidExpiry={props.updatePrepaidExpiry}
                                            updateCurrentSupplier={props.updateCurrentSupplier}
                                            updateModem={props.updateModem}
                                            updateIncludeDealsOnly={props.updateIncludeDealsOnly}
                                            updateAdvancedOptions={props.updateAdvancedOptions}
                                        />
                                    </TabContent>
                                    <TabContent name="faq" isActive={isTabActive}>
                                        <FaqTab html={props.faqContentFragment} />
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pad-t-5 pad-b-3">
                        <div className="row">
                            <div className="col-xs-24 text-right">
                                <button className="btn btn-info" data-dismiss="modal">
                                    {props.resources.cancel}
                                </button>
                                <button className="btn btn-primary mar-l-2" onClick={applyFilters} data-dismiss="modal">
                                    {props.resources.applyAll}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connector(FilterModal);
