import { produce } from 'immer';
import defaultTo from 'lodash/defaultTo';
import isString from 'lodash/isString';
import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import type { SuppliersModel } from '../../../../../Shared/js/@types/SuppliersModel';
import { RootState } from '../../../../../Shared/js/redux/rootReducer';
import type { SearchCriteria } from '../../../../js/@types/SearchCriteria';
import type { SearchModel } from '../../../../js/@types/SearchModel';
import { actions as searchActions } from '../../../../js/redux/searchSlice';
import BroadbandActions from '../../actions/BroadbandActions';
import DataFilter from './main/DataFilter';
import ErrorPanel from './main/ErrorPanel';
import ShowFiltersButton from './main/ShowFiltersButton';
import SpeedFilter from './main/SpeedFilter';
import SuppliersFilter from './main/SuppliersFilter';
import FilterModal from './modal/FilterModal';

const ModalOpenEventName = 'Filters.OpenModal';

const mapStateToProps = (state: RootState, ownProps: { modal?: RootState['broadband']['modal'] }) => {
    return produce(ownProps, draft => {
        const productAreaState = state.broadband;
        draft.modal = defaultTo(productAreaState?.modal, ownProps.modal);
    });
};

const mapDispatchToProps = {
    updateCriteria: searchActions.updateCriteria,
    updateData: searchActions.updateData,
    updateSpeed: searchActions.updateSpeed,
    updateSuppliers: searchActions.updateSuppliers
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface Props extends PropsFromRedux {
    criteria: SearchCriteria;
    legacyActions?: BroadbandActions;

    filtersData: SearchModel['filters'];
    hideSupplierFilter: boolean;
    showMaxUpfrontSlider: boolean;
    suppliers: SuppliersModel;
    unavailableConnectionTypes: never[];
    showModal: (tabName?: string) => void;
}

const Filters = (props: Props) => {
    const handleShowModal = useCallback(
        (tabName?: string | void | unknown) => {
            if (isString(tabName)) {
                props.showModal(tabName);
            } else {
                props.showModal();
            }
        },
        [props]
    );

    const handleOnMount = useCallback(() => {
        wo$('body').on(ModalOpenEventName, handleShowModal);

        // Unmount
        return () => {
            wo$('body').off(ModalOpenEventName, handleShowModal);
        };
    }, [handleShowModal]);

    const handleShowAllProviders = useCallback(() => {
        handleShowModal('providers');
    }, [handleShowModal]);

    const handleRetry = useCallback(() => {
        props.legacyActions.actions.searchRetry();
    }, [props.legacyActions.actions]);

    const handleModalCriteriaChanged = useCallback(
        (criteria: SearchCriteria) => {
            props.updateCriteria(criteria);
        },
        [props]
    );

    const selectedSuppliers = props.criteria.common.suppliers;

    // TODO: Do not use properties with the same name as existing built-in functions, i.e. Array.values()
    // HACK: To avoid confusion between Array.values() and our custom property with the same name
    const selectedSupplierKeys = Array.isArray(selectedSuppliers) ? selectedSuppliers : selectedSuppliers?.values;

    const isBusinessOnly =
        props.criteria.common.customerType && props.criteria.common.customerType.toLowerCase() === 'business';

    const getSupplierKey = useCallback(item => item.shortUrl, []);

    useEffect(() => handleOnMount(), [handleOnMount]);

    return (
        <React.Fragment>
            <FilterModal
                key="filters"
                {...props.filtersData}
                criteria={props.criteria}
                suppliers={props.suppliers}
                onCriteriaChanged={handleModalCriteriaChanged}
                hideSupplierFilter={props.hideSupplierFilter}
                showMaxUpfrontSlider={props.showMaxUpfrontSlider}
                unavailableConnectionTypes={props.unavailableConnectionTypes}
            />
            <div id="filters-original">
                <div className="row pad-y-6 pad-y-3-sm pad-b-0-xs filters-wrapper">
                    <SuppliersFilter
                        suppliers={props.suppliers}
                        selectedKeys={selectedSupplierKeys}
                        isBusinessOnly={isBusinessOnly}
                        showHeader={true}
                        showTextOnly={props.hideSupplierFilter}
                        textContent={props.filtersData.defaultSupplierAvailableText}
                        onChanged={props.updateSuppliers}
                        onShowAll={handleShowAllProviders}
                        getKey={getSupplierKey}
                    />
                    <div className="col-xs-24 hidden-sm col-md-8 col-lg-9 filters-2">
                        <div className="row">
                            <SpeedFilter
                                {...props.filtersData.speedSlider}
                                current={props.criteria.speed}
                                onChanged={props.updateSpeed}
                            />
                            <DataFilter
                                {...props.filtersData.dataSlider}
                                current={props.criteria.data}
                                onChanged={props.updateData}
                            />
                        </div>
                    </div>
                    {props.filtersData.showAllFilters ? (
                        <ShowFiltersButton title={props.filtersData.resources.filters} onClick={handleShowModal} />
                    ) : null}
                </div>
            </div>
            <ErrorPanel
                title={props.filtersData.resources.errorUpdatingResults}
                description={props.filtersData.resources.pleaseTryAgain}
                retryLabel={props.filtersData.resources.retry}
                onRetry={handleRetry}
            />
        </React.Fragment>
    );
};

export default connector(Filters);
