import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import type { Address } from '../../../../../Shared/js/@types/Address';
import { RootState } from '../../../../../Shared/js/redux/rootReducer';
import AddressChangeTracker from '../../../../../Shared/React/js/components/addressSearch/AddressChangeTracker';
import { dispatchAddressChangedEvent } from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import AppComponent from '../../../../../Shared/React/js/components/AppComponent';
import { usePrevious } from '../../../../../Shared/React/js/utils/Hooks';
import type { SearchCriteria } from '../../../../js/@types/SearchCriteria';
import type { SearchModel } from '../../../../js/@types/SearchModel';
import { broadbandApi } from '../../../../js/redux/broadbandApi';
import { actions as broadbandActions, mapSearchModelToState } from '../../../../js/redux/broadbandSlice';
import { actions as modalActions } from '../../../../js/redux/modalSlice';
import { actions, SearchSliceState } from '../../../../js/redux/searchSlice';
import LegacyActions from '../../actions/BroadbandActions';
import { generateQueryString } from '../../utils/BroadbandQueryString';
import Filters from '../filters/Filters';
import { AvailabilityCheckModal } from '../product/availabilityCheck/AvailabilityCheckPanel';

const connector = connect(
    (
        state: RootState,
        ownProps: {
            initialState: SearchModel;
            criteria?: SearchCriteria;
            searchSettings?: SearchSliceState['settings'];
            isInitialised?: boolean;
        }
    ) => {
        const isInitialised = !isEmpty(state.broadband?.search);
        const searchState = isInitialised ? state.broadband?.search : mapSearchModelToState(ownProps.initialState);

        return produce(ownProps, draft => {
            draft.criteria = searchState.criteria;
            draft.searchSettings = searchState.settings;
            draft.isInitialised = isInitialised;
        });
    },

    {
        init: broadbandActions.init,
        searchSuccess: actions.searchSuccess,
        searchError: actions.searchError,
        showModal: modalActions.showModal,
        ...actions
    }
);

export type Props = ConnectedProps<typeof connector>;

const QueryHelper = {
    getQueryString: (criteria: SearchCriteria, settings: SearchSliceState['settings']) => {
        return isEmpty(criteria) ? undefined : generateQueryString(criteria, undefined, settings.embeddedMode);
    },
    isEquivalent: (target: SearchCriteria, compareTo: SearchCriteria[], settings: SearchSliceState['settings']) => {
        const resetIgnoredProperties = (criteria: SearchCriteria) => {
            // Properties that are not used by the search
            // should be ignored for triggering the search request
            return produce(criteria, draft => {
                // Whould be good to fetch the results per tab for better performance and smaller page size,
                // instead of fetching all tabs at once as we do now
                draft.common.tab = null;
            });
        };

        const getClearQuery = (c: SearchCriteria) => QueryHelper.getQueryString(resetIgnoredProperties(c), settings);

        const query = getClearQuery(target);
        return compareTo.some(c => isEqual(query, getClearQuery(c)));
    }
};

const SearchComponent = (props: Props) => {
    const isInitialised = useRef(false);
    const [isProgress, setIsProgress] = useState(false);

    const [criteria, setCriteria] = useState(props.criteria);
    const previousCriteria = usePrevious(criteria);
    const defaultCriteria = useRef(cloneDeep(criteria));

    const [triggerGetSearchResultsQuery, searchResultsQuery] = broadbandApi.useLazyGetSearchResultsQuery();

    // TODO: Rewrite this
    const legacyActions = useRef(new LegacyActions());

    useEffect(() => {
        const selector = '#search-results-partial';
        const startProgress = () => WhistleOut.startProgress(selector, 0.1, 50);
        const endProgress = () => WhistleOut.endProgress(selector);

        if (isProgress) {
            startProgress();
        } else {
            endProgress();
        }
        return () => endProgress();
    }, [isProgress]);

    const searchPageRefresh = useCallback(
        (criteria: SearchCriteria) => {
            legacyActions.current.searchPageRefresh(
                criteria,
                props.searchSettings.searchUrl,
                props.searchSettings.suppressCoverageCheck,
                props.searchSettings.coverageUrl
            );
        },
        [props.searchSettings]
    );

    const search = useCallback(
        (criteria: SearchCriteria, force?: boolean) => {
            triggerGetSearchResultsQuery(
                {
                    baseUrl: props.initialState.resultsUrl,
                    queryString: QueryHelper.getQueryString(criteria, props.searchSettings)
                },
                !force
            );
        },
        [props.initialState.resultsUrl, props.searchSettings, triggerGetSearchResultsQuery]
    );

    const handleShowModal = useCallback(
        (tabName?: string) => {
            props.showModal({
                tabName,
                criteria
            });
        },
        [props, criteria]
    );

    const retrySearch = useCallback(() => {
        if (props.initialState.embeddedMode) {
            searchPageRefresh(criteria);
            return;
        }
        search(criteria, true);
    }, [props.initialState.embeddedMode, search, searchPageRefresh, criteria]);

    legacyActions.current.props = {
        embeddedMode: props.initialState.embeddedMode,
        nbnConnectionTypes: props.initialState.filters?.nbnConnectionTypes,
        criteria: criteria,
        defaultCriteria: defaultCriteria.current,
        coverage: props.initialState.state.coverage,
        coverageAddress: props.initialState.state.coverageAddress,
        searchSettings: props.searchSettings
    };
    legacyActions.current.actions = {
        searchSuccess: props.searchSuccess,
        searchError: props.searchError,
        searchRetry: retrySearch,

        disableMaximumResultLimits: props.disableMaximumResultLimits,
        hideFeaturedResults: props.hideFeaturedResults,
        includeNbnConnections: props.includeNbnConnections,
        updateConnectionTypes: props.updateConnectionTypes,
        updateContractType: props.updateContractType,
        updateCriteria: props.updateCriteria,
        updateData: props.updateData,
        updateModem: props.updateModem,
        updatePlanSummarySupplier: props.updatePlanSummarySupplier,
        updateSort: props.updateSort,
        updateSpeed: props.updateSpeed,
        updateSuppliers: props.updateSuppliers,
        updateTab: props.updateTab,
        showModal: handleShowModal,
        widenResults: props.widenResults
    };

    const handleAddressChanged = useCallback(
        (address: Address) => {
            if (address?.label === criteria.common.address?.label) {
                return;
            }

            dispatchAddressChangedEvent({
                // TODO: Fix eslint error
                // eslint-disable-next-line @typescript-eslint/no-invalid-this
                sender: this,
                address: address
            });

            props.updateAddress(address);
            WhistleOut.setCoverageCookie(null);
        },
        [criteria.common.address?.label, props]
    );

    const handleSpeedTestResultsChanged = useCallback((e: { detail: unknown }) => {
        if (!e.detail) {
            return;
        }
        legacyActions.current.bindResults();
    }, []);

    const handleTransactUrlChange = useCallback(() => {
        setIsProgress(false);
    }, []);

    const handleShowPlans = useCallback(
        (address: Address) => {
            setIsProgress(false);
            WhistleOut.scrollTo('body');
            handleAddressChanged(address);
        },
        [handleAddressChanged]
    );

    useEffect(() => {
        const init = () => {
            if (!isInitialised.current) {
                props.init(props.initialState);
                legacyActions.current.init();
                isInitialised.current = true;
            }
        };

        init();
        wo$(document).on('woSpeedTest.resultUpdated', handleSpeedTestResultsChanged);

        return () => {
            wo$(document).off('woSpeedTest.resultUpdated', handleSpeedTestResultsChanged);
        };
    }, [handleSpeedTestResultsChanged, props]);

    useEffect(() => setCriteria(props.criteria), [props.criteria]);
    useEffect(() => setIsProgress(searchResultsQuery.isFetching), [searchResultsQuery.isFetching]);

    useEffect(() => {
        const onCriteriaChange = () => {
            if (!props.isInitialised) {
                return;
            }

            if (
                QueryHelper.isEquivalent(
                    criteria,
                    [previousCriteria, props.searchSettings.resultsSearchCriteria],
                    props.searchSettings
                )
            ) {
                return;
            }

            if (props.initialState.embeddedMode && !props.searchSettings.enforceAjax) {
                searchPageRefresh(criteria);
                return;
            }

            search(criteria);
        };
        onCriteriaChange();
    }, [
        criteria,
        previousCriteria,
        props.initialState.embeddedMode,
        props.isInitialised,
        props.searchSettings,
        search,
        searchPageRefresh
    ]);

    useEffect(() => {
        const onSearchResult = () => {
            if (searchResultsQuery.isFetching) {
                return;
            }

            if (searchResultsQuery.error) {
                legacyActions.current.onAjaxSearchError();
                return;
            }

            if (!searchResultsQuery.data) {
                return;
            }

            legacyActions.current.onAjaxSearchSuccess(searchResultsQuery.data);
        };
        onSearchResult();
    }, [searchResultsQuery.data, searchResultsQuery.error, searchResultsQuery.isFetching]);

    const addressSearch = useMemo(() => props.initialState.addressSearch, [props.initialState.addressSearch]);

    return (
        <>
            <AddressChangeTracker onAddressChange={handleAddressChanged} initialValue={criteria.common.address} />
            <AvailabilityCheckModal
                shouldOpenTransactLink
                address={criteria.common.address}
                countryCode={addressSearch.countryCode}
                addressSearchPlaceHolder={addressSearch.text.addressSearchPlaceHolder}
                tooltipLink={addressSearch.text.tooltipLink}
                tooltipDescription={addressSearch.text.tooltipDescription}
                queryDelay={addressSearch.queryDelay}
                apiKey={addressSearch.apiKey}
                isSearchPage={!props.searchSettings.embeddedMode}
                onTransactUrlChange={handleTransactUrlChange}
                onShowPlans={handleShowPlans}
            />
            {props.initialState.filters && (
                <Filters
                    filtersData={props.initialState.filters}
                    suppliers={props.searchSettings.suppliers}
                    criteria={criteria}
                    hideSupplierFilter={props.searchSettings.hideSupplierFilter}
                    showMaxUpfrontSlider={props.searchSettings.showMaxUpfrontSlider}
                    unavailableConnectionTypes={props.searchSettings.unavailableConnectionTypes}
                    legacyActions={legacyActions.current}
                    showModal={handleShowModal}
                />
            )}
        </>
    );
};

const SearchConnected = connector(SearchComponent);

export default function Search(props: SearchModel) {
    return (
        <AppComponent>
            <SearchConnected initialState={props} />
        </AppComponent>
    );
}
