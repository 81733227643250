import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SupplierListItem from './SupplierListItem';

export default class SuppliersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initiallySelected: props.selectedShortUrls,
            rawSelectedShortUrls: null
        };

        this.supplierCheckedChanged = this.supplierCheckedChanged.bind(this);
        this.renderSupplierListItem = this.renderSupplierListItem.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return JSON.stringify(props.selectedShortUrls || []) !== JSON.stringify(state.initiallySelected || [])
            ? {
                initiallySelected: props.selectedShortUrls,
                rawSelectedShortUrls: null
            }
            : null;
    }

    supplierCheckedChanged(supplierId, checked) {
        const supplier = this.props.items.filter(p => p.id === supplierId)[0];

        // Use .slice() to avoid modifying the source array
        let selectedShortUrls = (this.state.rawSelectedShortUrls || this.props.selectedShortUrls || []).slice();
        if (checked) {
            selectedShortUrls.push(supplier.shortUrl);
        } else {
            selectedShortUrls = selectedShortUrls.filter(p => p !== supplier.shortUrl);
        }

        this.setState({ rawSelectedShortUrls: selectedShortUrls });
        if (this.props.onCheckedChanged) {
            this.props.onCheckedChanged(supplierId, checked);
        }
    }

    renderSupplierListItem() {
        const selectedShortUrls = this.state.rawSelectedShortUrls || this.props.selectedShortUrls || [];

        return this.props.items.map(supplier => {
            return (
                <SupplierListItem
                    key={`other-supplier-${supplier.id}`}
                    supplierId={supplier.id}
                    name={supplier.name}
                    checked={selectedShortUrls.some(p => supplier.shortUrl === p)}
                    onCheckedChanged={this.supplierCheckedChanged}
                    disabled={this.props.disabled}
                    isVerticallyAligned={this.props.isVerticallyAligned}
                />
            );
        });
    }

    render() {
        const defaultStyles = 'supplier-list block-grid-xs-2 block-grid-md-3 block-grid-condensed pad-t-3';
        return this.props.isVerticallyAligned ?
            <>
                {this.renderSupplierListItem()}
            </>
            :
            <ul className={this.props.className || defaultStyles}>
                {this.renderSupplierListItem()}
            </ul>;
    }
}

/* eslint-disable import/no-named-as-default-member */
SuppliersList.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    selectedShortUrls: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool
};
/* eslint-enable */
