import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SuppliersList from '../../../../../../Shared/React/js/components/suppliers/SuppliersList';

// TODO: Almost the same as Tablets SuppliersTab (except for networks), consider extracting common component
export default class SuppliersTab extends Component {
    constructor(props) {
        super(props);

        this.init(props);
        this.state = this.getInitialState(props);

        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.onSupplierChecked = this.onSupplierChecked.bind(this);
        this.onPersonalChange = this.onPersonalChange.bind(this);
        this.onBusinessChange = this.onBusinessChange.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
    }

    init(props) {

        this.availableShortUrls = props.suppliers
            .filter(p => p.isAvailable)
            .map(p => p.shortUrl);

        this.personalShortUrls = props.suppliers
            .filter(p => p.isAvailable && p.isPersonal)
            .map(p => p.shortUrl);

        this.businessShortUrls = props.suppliers
            .filter(p => p.isAvailable && p.isBusiness)
            .map(p => p.shortUrl);

        this.isPersonalAvailable = this.personalShortUrls.length > 0;
        this.isBusinessAvailable = this.businessShortUrls.length > 0;
    }

    getInitialState(props) {
        const customerType = props.customerType;
        const isPersonal = !customerType || customerType.toLowerCase() === 'personal';

        let isBusiness = !customerType || customerType.toLowerCase() === 'business';
        if (!this.isBusinessAvailable)
            isBusiness = false;

        let selectedShortUrls = props.selectedShortUrls || [];
        if (selectedShortUrls.length === 0) {
            if (isPersonal) {
                selectedShortUrls = selectedShortUrls.concat(this.personalShortUrls);
            }
            if (isBusiness) {
                selectedShortUrls = selectedShortUrls.concat(this.businessShortUrls);
            }
        }

        return {
            selectedShortUrls: (selectedShortUrls || this.availableShortUrls).slice(),
            isPersonal: isPersonal,
            isBusiness: isBusiness,
            isAllSelected: this.getIsAllSelected(selectedShortUrls)
        };
    }

    onSupplierChecked(supplierId, checked) {
        const supplier = this.props.suppliers.filter(p => p.id === supplierId)[0];

        // Use .slice() to avoid modifying the source array
        let selectedShortUrls = this.state.selectedShortUrls.slice();
        if (checked) {
            selectedShortUrls.push(supplier.shortUrl);
        } else {
            selectedShortUrls = selectedShortUrls.filter(p => p !== supplier.shortUrl);
        }
        this.setState(
            {
                selectedShortUrls: selectedShortUrls,
                isPersonal: this.state.isPersonal || checked && supplier.isPersonal,
                isBusiness: this.state.isBusiness || checked && supplier.isBusiness && !supplier.isPersonal,
                isAllSelected: this.getIsAllSelected(selectedShortUrls)
            },
            this.onSelectionChanged
        );
    }

    onSelectAllChange() {
        if(!this.state.isAllSelected){
            this.selectAll();
        }else{
            this.selectNone();
        }   
    }

    onPersonalChange() {
        const checked = !this.state.isPersonal;
        const selectedShortUrls = this.getSelectedUrlsForCustomerType(
            checked,
            this.personalShortUrls,
            this.state.isBusiness,
            this.businessShortUrls
        );

        this.setState(
            {
                isPersonal: checked,
                isAllSelected: this.getIsAllSelected(selectedShortUrls),
                selectedShortUrls: selectedShortUrls
            },
            this.onSelectionChanged
        );
    }

    onBusinessChange() {
        const checked = !this.state.isBusiness;
        const selectedShortUrls = this.getSelectedUrlsForCustomerType(
            checked,
            this.businessShortUrls,
            this.state.isPersonal,
            this.personalShortUrls
        );

        this.setState(
            {
                isBusiness: checked,
                isAllSelected: this.getIsAllSelected(selectedShortUrls),
                selectedShortUrls: selectedShortUrls
            },
            this.onSelectionChanged
        );
    }

    getIsAllSelected(selectedShortUrls) {
        return [...new Set(selectedShortUrls)].length === this.availableShortUrls.length;
    }

    getSelectedUrlsForCustomerType(typeChecked, typeShortUrls, otherTypeChecked, otherTypeShortUrls) {
        if (typeChecked) {
            // Add
            return this.state.selectedShortUrls.concat(
                typeShortUrls.filter(p => this.availableShortUrls.some(a => a === p))
            );
        }

        return this.state.selectedShortUrls.filter(s =>
            // Exclude all of this type
            !typeShortUrls.some(p => p === s) ||
            // but keep for the other if it's checked
            otherTypeChecked && otherTypeShortUrls.some(p => p === s));
    }

    selectAll() {
        // Ignore, if all are already selected
        if (this.state.selectedShortUrls.length === this.availableShortUrls.length) {
            return;
        }

        this.setState({
            selectedShortUrls: this.availableShortUrls,
            isPersonal: this.isPersonalAvailable && true,
            isBusiness: this.isBusinessAvailable && true,
            isAllSelected: true

        }, this.onSelectionChanged);
    }

    selectNone() {
        this.setState({
            selectedShortUrls: [],
            isPersonal: false,
            isBusiness: false,
            isAllSelected: false

        }, this.onSelectionChanged);
    }

    onSelectionChanged() {
        if (!this.props.onSelectionChanged) {
            return;
        }

        const selectedShortUrls = this.state.selectedShortUrls.length === this.availableShortUrls.length
            ? []
            : this.state.selectedShortUrls;

        const customerType = this.state.isPersonal && !this.state.isBusiness
            ? 'Personal'
            : this.state.isBusiness && !this.state.isPersonal
                ? 'Business'
                : null;

        this.props.onSelectionChanged(selectedShortUrls, customerType);
    }

    render() {
        const all = this.props.suppliers;
        const availableSuppliers = all.filter(item => !!item.isAvailable);

        const resources = this.props.resources;
        return (
            <React.Fragment>
                <div className="row pad-t-2 pad-b-3">
                    <div className="col-xs-24">
                        <h5 className="font-6 mar-0">
                            {this.props.hasAddress ? resources.providersInYourArea : resources.providers}
                        </h5>
                        <div className="checkbox-tick font-4 pad-t-8 pad-b-3">
                            <input
                                type="checkbox"
                                id="selectAll-checkbox"
                                checked={this.state.isAllSelected}
                                onChange={this.onSelectAllChange}
                            />
                            <label className="font-300" htmlFor="selectAll-checkbox">
                                <span>{this.props.resources.selectAll}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal-results-broadband-grid bor-t-1 pad-y-5">
                    <SuppliersList
                        className="supplier-list block-grid-xs-2 block-grid-md-3 block-grid-condensed pad-t-3"
                        items={availableSuppliers}
                        selectedShortUrls={this.state.selectedShortUrls}
                        onCheckedChanged={this.onSupplierChecked}
                        isVerticallyAligned={true}
                    />
                </div>
                <div className="row">
                    {(() => {
                        const unavailableSuppliers = all.filter(s => !s.isAvailable);
                        if (unavailableSuppliers.length) {
                            return (
                                <div className="col-xs-24 pad-y-2 bor-t-1 mar-t-2">
                                    <button
                                        className="btn btn-link btn-block"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseUnavailableProviders"
                                        aria-controls="collapseUnavailableProviders"
                                    >
                                        {this.props.resources.unavailableSuppliersInYourArea}
                                        <span className="fa fa-chevron-down font-5"></span>
                                    </button>
                                    <div className="collapse in" id="collapseUnavailableProviders">
                                        <SuppliersList
                                            className="supplier-list block-grid-xs-2 block-grid-md-3 block-grid-condensed pad-t-3"
                                            items={unavailableSuppliers}
                                            selectedShortUrls={this.state.selectedShortUrls}
                                            isVerticallyAligned={false}
                                            disabled
                                        />
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })()}
                </div>
                <div className="row bor-t-1 pad-t-3 mar-t-2">
                    <div className="col-xs-24">
                        <ul className="supplier-list block-grid-xs-2 block-grid-md-3 block-grid-condensed pad-t-3">
                            <li>
                                <div className="checkbox-tick font-4">
                                    <input
                                        type="checkbox"
                                        id="personal-checkbox"
                                        checked={this.state.isPersonal}
                                        onChange={this.onPersonalChange}
                                        disabled={!this.isPersonalAvailable}
                                    />
                                    <label className="font-300" htmlFor="personal-checkbox">
                                        <span>{this.props.resources.personal}</span>
                                    </label>
                                </div>
                            </li>
                            {this.isBusinessAvailable && (
                                <li>
                                    <div className="checkbox-tick font-4">
                                        <input
                                            type="checkbox"
                                            id="business-checkbox"
                                            checked={this.state.isBusiness}
                                            onChange={this.onBusinessChange}
                                            disabled={!this.isBusinessAvailable}
                                        />
                                        <label className="font-300" htmlFor="business-checkbox">
                                            <span>{this.props.resources.business}</span>
                                        </label>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/* eslint-disable import/no-named-as-default-member */
SuppliersTab.propTypes = {
    resources: PropTypes.object,
    selectedShortUrls: PropTypes.arrayOf(PropTypes.string),
    customerType: PropTypes.string,
    hasAddress: PropTypes.bool,
    suppliers: PropTypes.arrayOf(PropTypes.object)
};
/* eslint-enable */
