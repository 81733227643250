import React, { Component, createRef } from 'react';

import ConnectionType from './ConnectionType';

export default class ConnectionTab extends Component {

    constructor(props) {
        super(props);

        this.allShortUrls = [
            ...this.props.connectionTypeTiers.tier1ConnectionTypes,
            ...this.props.connectionTypeTiers.tier2ConnectionTypes,
            ...this.props.connectionTypeTiers.tier3ConnectionTypes
        ].map(p => p.shortUrl);

        const shortUrls = (props.criteria.connectionTypes || {}).values || [];
        this.state = {
            allUnselected: false,
            selectedShortUrls: shortUrls,
            isAllSelected: shortUrls.length === 0 ? true : false
        };

        this.wrapper = createRef();
        this.renderConnectionType = this.renderConnectionType.bind(this);
        this.handleConnectionTypeValueChanged = this.handleConnectionTypeValueChanged.bind(this);
        this.toggleConnections = this.toggleConnections.bind(this);
        this.toggleTier1 = this.toggleTier1.bind(this);
        this.toggleTier2 = this.toggleTier2.bind(this);
        this.toggleTier3 = this.toggleTier3.bind(this);
        this.toggleConnectionCategory = this.toggleConnectionCategory.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.renderConnectionTypeCategory = this.renderConnectionTypeCategory.bind(this);
    }


    isChecked(connectionType) {
        return this.state.selectedShortUrls.length === 0 && !this.state.allUnselected
            || connectionType && connectionType.shortUrl && this.state.selectedShortUrls.includes(connectionType.shortUrl);
    }

    handleConnectionTypeValueChanged(connectionType, checked) {
        console.log('handleConnectionTypeValueChanged', {
            item: connectionType.title,
            checked: checked
        });

        if (!connectionType || !connectionType.shortUrl) {
            const error = new Error('connectionType or the short url is undefined');
            error.data = connectionType;
            throw error;
        }

        if (checked) {
            this.AddItem(connectionType);
        } else {
            this.RemoveItem(connectionType);
        }
    }

    toggleConnections(connections) {
        let anyOneUnChecked = false;
        connections.forEach((connection) => {
            if (!this.isChecked(connection)) {
                anyOneUnChecked = true;
            }
        });

        if (anyOneUnChecked) {
            this.AddItems(connections);
        } else {
            this.RemoveItems(connections);
        }
    }

    updateConnectionTypes(allUnselected, selectedShortUrls) {
        const selected = selectedShortUrls.length !== this.allShortUrls.length
            ? selectedShortUrls
            : [];

        const isAllSelected = !allUnselected && selected.length===0;
        this.setState({
            allUnselected: allUnselected,
            selectedShortUrls: selected,
            isAllSelected: isAllSelected
        });
        this.props.updateConnectionTypes(selected);
    }

    AddItem(connectionType) {
        if (this.isChecked(connectionType)) {
            return;
        }

        let newValue = [...this.state.selectedShortUrls];
        newValue.push(connectionType.shortUrl);

        this.updateConnectionTypes(false, newValue);
    }

    AddItems(connectionTypes) {
        let newValue = [...this.state.selectedShortUrls];
        connectionTypes.forEach(t => {
            if (wo$.inArray(t.shortUrl, newValue) === -1) {
                newValue.push(t.shortUrl);
            }
        });

        this.updateConnectionTypes(false, newValue);
    }

    RemoveItem(connectionType) {
        if (!this.isChecked(connectionType)) {
            return;
        }

        // Removing last connection type
        if (this.state.selectedShortUrls.length === 1) {
            this.unSelectAll();
            return;
        }

        const current = this.state.selectedShortUrls.length === 0
            ? this.allShortUrls
            : this.state.selectedShortUrls;

        this.updateConnectionTypes(
            false,
            current.filter(p => p !== connectionType.shortUrl)
        );
    }

    RemoveItems(connectionTypes) {
        const current = this.state.selectedShortUrls.length === 0
            ? this.allShortUrls
            : this.state.selectedShortUrls;

        let newValue = [...current];
        connectionTypes.forEach(t => {
            const index = newValue.indexOf(t.shortUrl);
            if (index > -1) {
                newValue.splice(index, 1);
            }
        });

        if (newValue.length === 0) {
            this.unSelectAll();
            return;
        }

        this.updateConnectionTypes(false, newValue);
    }

    onSelectAllChange() {
        if(!this.state.isAllSelected){
            this.selectAll();
        }else{
            this.unSelectAll();
        }
    }
    selectAll() {
        this.updateConnectionTypes(false, []);
    }

    unSelectAll() {
        this.updateConnectionTypes(true, []);
    }

    renderConnectionType(connectionType) {
        const disabled =
            this.props.unavailableConnectionTypes &&
            this.props.unavailableConnectionTypes.length &&
            this.props.unavailableConnectionTypes.findIndex(c => connectionType.shortUrl === c.shortUrl) > -1;

        const checked = disabled === true
            ? false
            : this.isChecked(connectionType);

        return (
            <ConnectionType
                key={connectionType.id}
                item={connectionType}
                disabled={disabled}
                checked={checked}
                onCheckedChanged={this.handleConnectionTypeValueChanged}
            />
        );
    }

    toggleTier1() {
        this.toggleConnections(this.props.connectionTypeTiers.tier1ConnectionTypes);
    }

    toggleTier2() {
        this.toggleConnections(this.props.connectionTypeTiers.tier2ConnectionTypes);
    }

    toggleTier3() {
        this.toggleConnections(this.props.connectionTypeTiers.tier3ConnectionTypes);
    }

    toggleConnectionCategory = connectionTypes => () => {
        this.toggleConnections(connectionTypes);
    };

    renderConnectionTypeCategory(connectionTypeCategory, index) {
        let titleClass = '[ col-xs-24 ] [ col-sm-8 col-md-8 ] [ pad-x-5 pad-x-2-xs pad-x-2-sm ]';
        
        if (this.props.connectionTypeCategories.length === 3) {
            switch (index) {
                case 0:
                    titleClass = '[ col-xs-24 ] [ col-sm-8 col-md-8 ] [ pad-x-5 pad-x-2-xs pad-x-2-sm ]';
                    break;
                case 1:
                    titleClass = '[ col-xs-24 ] [ col-sm-11 col-md-9 ] [ pad-x-5 pad-x-2-xs pad-x-2-sm ]';
                    break;
                case 2:
                    titleClass = '[ col-xs-24 ] [ col-sm-5 col-md-7 ] [ pad-x-5 pad-x-2-xs pad-x-2-sm ]';
                    break;
            }
        }

        if (connectionTypeCategory)
            return (
                <div className={titleClass} key={connectionTypeCategory.title}>
                    <div
                        className="mar-b-2 mar-t-4 pad-b-2"
                        onClick={this.toggleConnectionCategory(connectionTypeCategory.connectionTypes)}
                    >
                        <h3 className="font-5 mar-t-5 mar-b-2 font-700 display-inline-xs">
                            {connectionTypeCategory.title}
                        </h3>
                    </div>
                    <div className="mar-b-3">
                        {connectionTypeCategory.connectionTypes.map(this.renderConnectionType)}
                    </div>
                </div>
            );
    }

    render() {
        function createMarkup(html) { return { __html: html }; }

        let connectionTypeCategoriesClass = 'display-flex flex-wrap-wrap justify-content-space-between';
        if (this.props.connectionTypeCategories && this.props.connectionTypeCategories.length === 2) {
            connectionTypeCategoriesClass = 'display-flex flex-wrap-wrap';
        }

        return (
            <div ref={this.wrapper}>
                <div className="row pad-t-2 mar-b-3">
                    <div className="pad-x-5 pad-x-2-xs pad-x-2-sm">
                        <div className="col-xs-24">
                            <h5 className="font-6 mar-0">
                                {this.props.resources.connectionType}
                            </h5>
                            <div className="checkbox-tick font-4 pad-t-8 pad-b-3">
                                <input
                                    type="checkbox"
                                    id="connection-selectAll-checkbox"
                                    checked={this.state.isAllSelected}
                                    onChange={this.onSelectAllChange}
                                />
                                <label 
                                    className="font-300" 
                                    htmlFor="connection-selectAll-checkbox"
                                >
                                    <span>
                                        {this.props.resources.selectAll}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mar-y-3" />
                <div className="row mar-b-2">
                    <div className="col-xs-24">
                        <div className={connectionTypeCategoriesClass}>
                            {this.props.connectionTypeCategories.map(this.renderConnectionTypeCategory)}
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={createMarkup(this.props.connectionTypesContentFragment)} />
            </div>
        );
    }
}
