import React, { Component } from 'react';

export default class ConnectionType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rawChecked: null
        };

        this.onCheckedChanged = this.onCheckedChanged.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return props.value === state.rawValue
            ? { rawChecked: null }
            : null;
    }

    onCheckedChanged(e) {
        const checked = e.currentTarget.checked;
        this.setState({ rawChecked: checked });

        if (this.props.onCheckedChanged) {
            this.props.onCheckedChanged(this.props.item, checked);
        }
    }

    render() {
        const checked = this.state.rawChecked !== null
            ? this.state.rawChecked
            : this.props.checked;

        return (
            <div className="checkbox-tick mar-b-2">
                <input
                    type="checkbox"
                    id={this.props.item.id}
                    disabled={this.props.disabled}
                    checked={checked}
                    onChange={this.onCheckedChanged}
                />
                <label className="display-block font-4 c-black font-400" htmlFor={this.props.item.id}>
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: this.props.item.title }} />
                    </span>
                </label>
            </div>
        );
    }
}
