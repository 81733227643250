import React, { useCallback, useEffect, useRef } from 'react';

import SliderWithLabel from '../../../../../../Shared/React/js/components/sliders/SliderWithLabel';

const ModalDataSlider = ({
    current,
    values,
    anyDataLabel,
    atLeastLabel,
    changeData
}) => {
    const popoverRef = useRef(null);

    useEffect(() => {
        const popover = wo$(popoverRef.current);
        WhistleOut.applyPopover(popover);
    }, []);

    const getLabel = useCallback(labelText => <strong className="font-5">{labelText}</strong>, []);

    return (
        <>
            <SliderWithLabel
                current={current}
                values={values}
                anyLabel={anyDataLabel}
                fromLabel={atLeastLabel}
                containerClassName="pad-t-4"
                labelTemplate={getLabel}
                sliderClassName="rc-slider-container pad-x-3 pad-x-5-xs mar-y-2"
                onValueChanged={changeData}
            />
        </>
    );
};

export default ModalDataSlider;
